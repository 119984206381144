<template>
  <div>
    <van-checkbox-group
      v-model="result"
      v-for="(item, index) in attribute"
      :key="index"
      class="van-hairline--bottom"
    >
      <van-field
        clickable
        input-align="right"
        error-message-align="right"
        :disabled="checkIsOk[index] || checkSplit(item)"
        @focus="selectedItem(index)"
        v-model="item.price"
        type="number"
        :name="item.subjectName"
        :error-message="
          checkAmount[index]
            ? '输入的金额不在允许范围'
            : item.orderStatus === '部分支付' || item.orderStatus === '支付完成'
            ? '已支付' + item.actuallyPaidAmount + '元'
            : item.orderStatus == '部分退款' || item.orderStatus == '已退款'
            ? '已退款'
            : ''
        "
        placeholder="请输入金额"
      >
        <template #label class="label">
          <van-checkbox
            :name="index"
            ref="checkboxes"
            :disabled="checkIsOk[index]"
            >{{ item.subjectName }}</van-checkbox
          >
        </template>
        <template #button>
          <span>元</span>
        </template>
      </van-field>
    </van-checkbox-group>
    <!-- 缴费合计 -->
    <van-cell-group class="van-hairline--top van-hairline--bottom">
      <van-cell
        title="缴费合计："
        :value="sumAmount"
        title-class="amount-title"
        value-class="amount-value"
      ></van-cell>
    </van-cell-group>
  </div>
</template>

<script>
import { Field, Button, CellGroup } from 'vant'
import { Checkbox, Cell, CheckboxGroup } from 'vant'

export default {
  name: 'PaySellect',
  components: {
    [Field.name]: Field,
    [Button.name]: Button,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup
  },
  props: {
    attribute: Array
  },
  data() {
    return {
      result: [] //选中的选项
    }
  },
  computed: {
    checkAmount() {
      //检查金额是否在合理范围
      let results = []
      for (const iterator of this.attribute) {
        if (
          this.$store.state.split &&
          parseFloat(iterator.price) > 0 &&
          parseFloat(iterator.price) <= parseFloat(iterator.shouldPayAmount) //拆单，输入金额在0到应付之间
        ) {
          //在范围
          //console.log(1)
          results.push(false)
          continue
        }
        if (
          !this.$store.state.split &&
          parseFloat(iterator.price) == parseFloat(iterator.shouldPayAmount)
        ) {
          //不拆单金额等于应付金额
          // console.log(2)
          results.push(false)
          continue
        }
        if (parseFloat(iterator.shouldPayAmount) == 0) {
          //自定义金额
          // console.log(3)
          results.push(false)
          continue
        }
        results.push(true)
      }
      return results
    },
    checkSplit() {
      return (item) => {
        if (item.orderStatus === '支付完成') {
          //支付完成禁用
          return true
        } else if (parseFloat(item.shouldPayAmount) == 0) {
          //自定义启用
          return false
        } else return !this.$store.state.split //是否拆单
      }
    },

    sumAmount() {
      //计算缴费总计
      let sumNum = 0
      for (let i = 0; i < this.attribute.length; i++) {
        if (this.result.indexOf(i) != -1)
          sumNum += Number(this.attribute[i].price)
      }
      return sumNum.toFixed(2) + ' 元'
    },
    checkIsOk() {
      //检查多选框状态
      let state = []
      for (let i = 0; i < this.attribute.length; i++) {
        this.attribute[i].flag = ''
        if (
          this.attribute[i].orderStatus === '支付完成' ||
          this.attribute[i].orderStatus === '部分退款' ||
          this.attribute[i].orderStatus === '已退款'
        ) {
          state[i] = true
          continue
        } else {
          state[i] = false
        }
        if (this.result.length > 0) {
          state[i] =
            this.attribute[this.result[0]].cusid != this.attribute[i].cusid //互斥
        }
      }
      for (let i = 0; i < this.result.length; i++) {
        this.attribute[this.result[i]].flag = '1'
      }
      return state
    }
  },
  methods: {
    selectedItem(index) {
      //自动勾选
      if (this.result.indexOf(index) < 0) {
        this.result.push(index)
      }
    }
  }
}
</script>

<style scoped lang="less">
.van-hairline--bottom {
  /deep/.van-field__label {
    width: 50%;
  }
}
.amount-title {
  color: #ed6a0c;
  font-size: 16px;
  font-family: emoji;
  font-weight: bold;
}
.amount-value {
  color: black;
  font-size: 18px;
  font-family: emoji;
  font-weight: bold;
}
</style>
