<template>
  <div class="base-message">
    <van-cell-group
      title="基本信息"
      border
      class="van-hairline--top van-hairline--bottom"
    >
      <van-cell
        v-for="(item, index) in content"
        :title="item.label"
        :value="item.value"
        :key="index"
      />
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: 'ListCell',
  props: {
    content: Array
  }
}
</script>

<style scoped lang="less">
@import '~@less/common.less';
.base-message {
  .message(10px 10px 0 0);
}
</style>
