var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.attribute),function(item,index){return _c('van-checkbox-group',{key:index,staticClass:"van-hairline--bottom",model:{value:(_vm.result),callback:function ($$v) {_vm.result=$$v},expression:"result"}},[_c('van-field',{attrs:{"clickable":"","input-align":"right","error-message-align":"right","disabled":_vm.checkIsOk[index] || _vm.checkSplit(item),"type":"number","name":item.subjectName,"error-message":_vm.checkAmount[index]
          ? '输入的金额不在允许范围'
          : item.orderStatus === '部分支付' || item.orderStatus === '支付完成'
          ? '已支付' + item.actuallyPaidAmount + '元'
          : item.orderStatus == '部分退款' || item.orderStatus == '已退款'
          ? '已退款'
          : '',"placeholder":"请输入金额"},on:{"focus":function($event){return _vm.selectedItem(index)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('van-checkbox',{ref:"checkboxes",refInFor:true,attrs:{"name":index,"disabled":_vm.checkIsOk[index]}},[_vm._v(_vm._s(item.subjectName))])]},proxy:true},{key:"button",fn:function(){return [_c('span',[_vm._v("元")])]},proxy:true}],null,true),model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", $$v)},expression:"item.price"}})],1)}),_c('van-cell-group',{staticClass:"van-hairline--top van-hairline--bottom"},[_c('van-cell',{attrs:{"title":"缴费合计：","value":_vm.sumAmount,"title-class":"amount-title","value-class":"amount-value"}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }