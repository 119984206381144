<template>
  <div class="orderPage">
    <head-text :title="this.$store.state.title"></head-text>
    <!--  页面主体  -->
    <van-empty v-show="!showFlag" description="暂无信息" />
    <div class="body" v-show="showFlag">
      <!--   基础信息   -->
      <div class="base-message">
        <list-cell :content="baseMessages"></list-cell>
      </div>
      <!-- 缴费信息 -->
      <div class="pay-message">
        <van-cell-group
          title="缴费信息"
          class="van-hairline--top van-hairline--bottom"
        >
          <pay-sellect :attribute="payMessage" ref="pay"></pay-sellect>
        </van-cell-group>
      </div>
      <!--   提交按钮   -->
      <div class="submit-button">
        <van-button
          round
          block
          loading-text="提交中..."
          size="large"
          @click="onSubmit()"
          type="info"
          :disabled="changeState === 0"
          :loading="subFlag"
          native-type="submit"
          >提交
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import request from '@/api/request'
import HeadText from '@/components/common/simple/HeadText.vue'
import PaySellect from '@/components/orderPage/simple/PaySellect.vue'
import ListCell from '@/components/orderPage/simple/ListCell'

export default {
  name: 'OrderPageSimple',
  components: {
    HeadText,
    PaySellect,
    ListCell
  },
  data() {
    return {
      showFlag: false, //true显示体，false显示暂无信息
      subFlag: false, //提交按钮状态
      baseMessages: [], //baseMessages, 基本信息配置
      payMessage: [], //payMessage, 缴费信息配置
      combination: 0, //总金额
      loading: true //loading
    }
  },
  computed: {
    changeState() {
      //监听多选框状态改变事件，改变提交按键状态。
      this.combination = 0
      for (let i = 0; i < this.payMessage.length; i++) {
        if (
          this.payMessage[i].flag === '1' &&
          this.payMessage[i].price !== '' &&
          parseFloat(this.payMessage[i].price) > 0
        ) {
          this.combination += Number(this.payMessage[i].price)
        }
      }
      return this.combination
    }
  },
  methods: {
    onSubmit() {
      //表单提交事件
      const form = {
        subjectList: [],
        remark: ''
      }
      let a = this.judgeStatus(form)
      if (a[0] !== 0) {
        this.startPay(...a) //支付发起
      }
    },

    startPay(form, payFlag) {
      //支付验证和发起支付
      if (payFlag) {
        Toast.fail('缴费金额不在允许的范围')
      } else {
        this.subFlag = true
        // 有code则提交
        if (this.$route.query.code) {
          form.wxcode = this.$route.query.code
        }
        // console.log(form) //提交的订单报文
        request
          .post('/payment/wx/school/order/pay', form)
          .then((res) => {
            this.subFlag = false
            window.location.href = res.content.url + '?' + res.content.body
          })
          .catch((err) => {
            this.subFlag = false
            console.error(err)
            Toast.fail(err.message)
          })
      }
    },

    judgeStatus(form) {
      //提交时判断输入的状态，生成表单
      let payFlag = false
      for (let i of this.$refs.pay.result) {
        //整合支付信息
        if (
          this.payMessage[i].price !== '' &&
          parseFloat(this.payMessage[i].price) > 0
        ) {
          //只提交选中且非空的订单
          if (this.$refs.pay.checkAmount[i]) {
            //判断是否输入值是否在范围
            payFlag = true
            break
          }
          if (
            this.payMessage[i].price.indexOf('.') + 3 >=
              this.payMessage[i].price.length ||
            this.payMessage[i].price.indexOf('.') < 0
          ) {
            //判断是否是两位小数
            form.subjectList.push({
              orderId: this.payMessage[i].orderId,
              subjectId: this.payMessage[i].id,
              shouldPayAmount: this.payMessage[i].price
            })
          } else {
            Toast.fail('金额不能出现两位以上的小数！')
            form = 0
            break
          }
        } else {
          Toast.fail('金额不能为0或空！')
          form = 0
          break
        }
      }

      return [form, payFlag]
    }
  },
  mounted() {
    if (this.$store.state.orderMessages.length > 0) {
      this.showFlag = true
      //console.log(this.$store.state.orderMessage)
      for (const argument of this.$store.state.orderMessage.baseMessage) {
        this.baseMessages.push({
          label: argument.value,
          value: argument.display
        })
      }
      this.payMessage = this.$store.state.orderMessage.payMessage
    }
  }
}
</script>

<style scoped lang="less">
@import '~@less/common.less';
.orderPage {
  .van-search__content {
    border: #1995fa solid 1px;
  }
  .body {
    padding: 0 10px;

    .pay-message {
      .message(0 0 10px 10px);
    }

    .submit-button {
      margin: 30px 20px;
    }
  }
}
</style>
